import React from 'react';

import './NoProviderMessage.scss';
import icons from '../../shared/assets/icons';

const { Exclamation } = icons;

export default function NoProviderMessage() {
  return (
    <div className="noProviderMessage">
      <div className="noProviderMessage_icon">
        <Exclamation />
      </div>
      <p className="noProviderMessage_text">
        Zie jij hier geen afspraken staan? Voeg je zorgverlener
        toe om deze in te kunnen zien
      </p>
      <a className="noProviderMessage_button" href="/profile-settings">
        Voeg toe
      </a>
    </div>
  );
}
