import React from 'react';
import icons from '../../shared/assets/icons/redesign';

import './CoursesLoginPopup.scss';

const { Check } = icons;

export default function CoursesLoginPopup({ customList, config, showPopup }) {
  return (
    <div className="coursesPopup-wrap">
      <div className="coursesPopup">
        <div className="coursesPopup-header">
          <h3 className="coursesPopup-header-title">Account nodig</h3>

          <span
            className="coursesPopup-close-button"
            onClick={() => showPopup(false)}
          >
            &times;
          </span>
        </div>

        <div className="coursesPopup-body">
          <h4 className="coursesPopup-body-title">
            Om de cursussen te kopen heb je een account nodig.
          </h4>

          <ul className="coursesPopup-body-list">
            {customList ? (
              customList.map((item, idx) => (
                <li key={idx} className="coursesPopup-body-list-item">
                  <div className="coursesPopup-body-list-item-icon-wrap">
                    <Check className="coursesPopup-body-list-item-icon" />
                  </div>
                  {item}
                </li>
              ))
            ) : (
              <>
                <li className="coursesPopup-body-list-item">
                  <div className="coursesPopup-body-list-item-icon-wrap">
                    <Check className="coursesPopup-body-list-item-icon" />
                  </div>
                  18 maanden onbeperkt toegang tot 10 online cursussen
                </li>

                <li className="coursesPopup-body-list-item">
                  <div className="coursesPopup-body-list-item-icon-wrap">
                    <Check className="coursesPopup-body-list-item-icon" />
                  </div>
                  Afgestemd op je zwangerschap, bevalling, de babytijd en je
                  partner
                </li>

                <li className="coursesPopup-body-list-item">
                  <div className="coursesPopup-body-list-item-icon-wrap">
                    <Check className="coursesPopup-body-list-item-icon" />
                  </div>
                  Gratis, afhankelijk van je verzekering. Anders maar 99,99 euro
                  voor het hele pakket
                </li>

                <li className="coursesPopup-body-list-item">
                  <div className="coursesPopup-body-list-item-icon-wrap">
                    <Check className="coursesPopup-body-list-item-icon" />
                  </div>
                  Meer dan 100 video’s en 1000+ minuten materiaal
                </li>
              </>
            )}
          </ul>

          <a
            href={config.SIGNUP_URL}
            className="coursesPopup-button coursesPopup-button-secondary"
          >
            Account aanmaken
          </a>

          <a
            href={config.LOGIN_URL}
            className="coursesPopup-button coursesPopup-button-primary"
          >
            Inloggen
          </a>
        </div>
      </div>
    </div>
  );
}
