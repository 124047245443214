import { createPortal } from 'react-dom';
import { useEffect, useState } from 'react';

export default function Portal({ children }) {
  const [el, setEl] = useState(null);

  useEffect(() => {
    const el = document.createElement('div');
    const mount = document.getElementById('portal-root');

    mount.appendChild(el);

    setEl(el);
    return () => mount.removeChild(el);
  }, []);

  return el && createPortal(children, el);
}
