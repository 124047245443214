import ReactDOMServer from 'react-dom/server';
import React, { useState, useEffect, useRef } from 'react';

import './SurveyQuickForm.scss';

import sendEvent from '../../../helpers/tracking';
import {
  openEmailClientWithFormData,
  prefillSurveyForm
} from '../../../helpers/form';

export default function SurveyForm({
  user,
  item,
  onSubmit,
  CustomHTML,
  metadata = {},
  addUserEvent,
  addFormAnswer,
  submitFormAnswer,
  showCompletedHTML,
  showCompletedStage,
  completeButtonLabel,
  scrollTopOnPageChange,
  quickFormTrackingCodes
}) {
  const form = useRef();
  const parsed = JSON.parse(item.data);
  const completeLabel = completeButtonLabel || 'Volgende';

  const [model, setModel] = useState(null);
  const [Survey, setSurvey] = useState(null);

  useEffect(() => {
    (async () => {
      const Survey = await import('survey-react');

      await import('survey-react/survey.css');

      const model = new Survey.Model(item.data);

      setModel(model);
      setSurvey(Survey);

      model.onAfterRenderQuestion.add(function (survey) {
        initialize(survey, model);

        Array.prototype.forEach.call(
          document.querySelectorAll('.sv_q_radiogroup_control_item'),
          (item) => {
            item.onclick = (e) => {
              e.target
                .closest('.sv_body')
                .querySelector('.sv_nav').style.display = 'flex';
            };

            const span = document.createElement('span');
            span.innerHTML = '<span class="checkmark-content">&#10003;</span>';
            span.className = 'checkmark';

            item.after(span);
          }
        );

        Array.prototype.forEach.call(
          document.querySelectorAll('.sv_q_checkbox_control_item'),
          (item) => {
            const span = document.createElement('span');

            span.innerHTML = '<span class="checkmark-content">&#10003;</span>';
            span.className = 'checkmark';

            item.after(span);
          }
        );
      });

      prefillSurveyForm(item.xuid, model, user);
    })();
  }, []);

  useEffect(() => {
    const completedButton = form.current?.querySelector('.sv_complete_btn');

    if (
      completedButton &&
      completeButtonLabel &&
      completedButton.value !== completeButtonLabel
    )
      completedButton.value = completeButtonLabel;
  });

  return (
    <div className="surveyQuickForm" ref={form}>
      {showCompletedStage && showCompletedHTML ? (
        <div className="surveyForm-completedHTML">
          {CustomHTML ? (
            <CustomHTML />
          ) : (
            parsed.completedHtml?.nl || parsed.completedHtml
          )}
        </div>
      ) : model && Survey ? (
        <Survey.Survey
          model={model}
          pagePrevText="Vorige"
          onComplete={onComplete}
          pageNextText={completeLabel}
          onCurrentPageChanged={initialize}
          focusFirstQuestionAutomatic={false}
        />
      ) : null}
    </div>
  );

  async function onComplete(survey) {
    if (onSubmit) onSubmit();

    // https://team.linkorb.com/cards/8015
    if (
      ['C9DatBYGQ0WC8_PQNGwn7w', 'wZitKGHgTkO4A5BjO1SQCw'].includes(
        item.xuid
      ) &&
      addUserEvent
    )
      addUserEvent({
        variables: {
          type: 'verzekeringen:complete-form',
          data: '{}'
        }
      });

    if (showCompletedHTML) {
      document.querySelector(
        '.courseModule-survey-form .surveyQuickForm'
      ).innerHTML = `<div class="surveyForm-completedHTML">${
        ReactDOMServer.renderToStaticMarkup(<CustomHTML />) ||
        parsed.completedHtml?.nl ||
        parsed.completedHtml
      }</div>`;

      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      form.current.hidden = true;
    }

    if (quickFormTrackingCodes?.verzenden)
      sendEvent(...quickFormTrackingCodes.verzenden);

    openEmailClientWithFormData(item.xuid, survey.data);

    if (addFormAnswer) {
      const response = await addFormAnswer({
        variables: {
          formXuid: item.xuid,
          data: JSON.stringify({ ...survey.data, ...metadata }),
          recipient: user?.username || 'anonymous'
        }
      });

      if (response?.data?.addFormAnswer?.xuid)
        await submitFormAnswer({
          variables: { formAnswerXuid: response.data.addFormAnswer.xuid }
        });
    }
  }

  function initialize(survey, model, opt) {
    const pageCount = getPageCount(model);
    if (scrollTopOnPageChange) window.scrollTo(0, 0);

    const currentPage = survey.currentPageNo + 1;

    if (quickFormTrackingCodes?.aanvragen && opt?.isNextPage)
      sendEvent(...quickFormTrackingCodes.aanvragen);
    if (quickFormTrackingCodes?.vorige && opt?.isPrevPage)
      sendEvent(...quickFormTrackingCodes.vorige);

    const nextButton = form.current?.querySelector('.sv_next_btn');
    const completeButton = form.current?.querySelector('.sv_complete_btn');

    if (currentPage === pageCount) {
      if (completeButton) {
        completeButton.value = 'Verzenden';
        completeButton.style.display = 'block';
      }

      if (nextButton) nextButton.style.display = 'none';
    } else if (completeButton) {
      completeButton.value = completeLabel;
    }
  }
}

function getPageCount(model) {
  if (model.visiblePageCount < model.PageCount)
    return model.visiblePageCount + 1;
  else return model.PageCount;
}
