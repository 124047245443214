export default function setUserPhoto({
  file,
  updateUserPhoto,
  setUserPhotoMutation
}) {
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = (e) => {
    if (updateUserPhoto) updateUserPhoto(e.target.result);

    const img = new Image();

    img.src = e.target.result;

    img.onload = () => {
      const canvas = document.createElement('canvas');

      canvas.width = 512;
      canvas.height = 512;

      const ctx = canvas.getContext('2d');

      ctx.drawImage(img, 0, 0, 512, 512);

      const dataUrl = ctx.canvas.toDataURL();

      const file = dataUrl.split(',')[1];

      setUserPhotoMutation({ variables: { file } });
    };
  };
}
