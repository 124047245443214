export const babyMonthList = [
  'baby-week-21',
  'baby-week-26',
  'baby-week-30',
  'baby-week-34',
  'baby-week-39',
  'baby-week-43',
  'baby-week-47',
  'baby-week-52'
];

export const pregnancyWeekTabs = {
  '1st trimester': {
    start: 0,
    end: 13
  },
  '2de trimester': {
    start: 13,
    end: 27
  },
  '3de trimester': {
    start: 27,
    end: 42
  }
};

export const pregnancyWeekList = Array.from({ length: 42 }).map((_, idx) => ({
  number: idx + 1,
  link: `/week-${idx + 1}`,
  label: idx === 0 ? 'Week' : 'Weken'
}));

export const babyWeekTabs = {
  '1e jaar': {
    start: 0,
    end: 25
  },

  '2de jaar': {
    start: 25,
    end: 37
  },

  '3rde jaar': {
    start: 37,
    end: 49
  },

  '4de jaar': {
    start: 49,
    end: 61
  }
};

export const babyWeekList = Array.from({ length: 25 })
  .map((_, idx) => ({
    number: idx < 17 ? idx : idx - 12,
    link: idx < 17 ? `/baby-week-${idx + 1}` : `/${babyMonthList[idx - 17]}`,
    label: idx === 1 ? 'Week' : idx < 17 ? 'Weken' : 'Maanden'
  }))
  .concat(
    Array.from({ length: 36 }).map((_, idx) => ({
      number: idx + 13,
      link: `/${idx < 11 ? 'dreumes' : idx < 35 ? 'peuter' : 'kleuter'}-${
        idx + 13
      }-maanden`,
      label: 'Maanden'
    }))
  );

export function getWeekContentLink(user) {
  if (!user.username || user.phaseNumeric <= 0) return;
  if (user.accountType === 'PARTNER')
    return `/partner-week-${user.phaseNumeric}`;
  if (user.status === 'PREGNANT') return `/week-${user.phaseNumeric}`;
  if (user.phaseNumeric <= 17) return `/baby-week-${user.phaseNumeric}`;

  const month = Math.ceil(user.phaseNumeric / 4);

  if (month > 12) return;

  return `/${babyMonthList[month - 5]}`;
}
