import React from 'react';
import { useRouter } from 'next/router';

import './BackButton.scss';

import icons from '../../shared/assets/icons/redesign';

const { ChevronLeft } = icons;

export default function BackButton({ path }) {
  const router = useRouter();

  return (
    <button
      className="back-btn"
      onClick={() => (path ? router.push(path) : router.back())}
    >
      <ChevronLeft className="back-btn-icon" />
      Terug
    </button>
  );
}
