import React, { useState } from 'react';

import './DatePicker.scss';
import { getString } from '../../../helpers/styles';
import ChevronLeft from '../../../shared/assets/icons/redesign/chevron-left.svg';
import ChevronRight from '../../../shared/assets/icons/redesign/chevron-right.svg';

export default function DatePicker(props) {
  const {
    title,
    onDateChange,
    showDatePicker,
    changeInitialDate,
    period: limitedPeriod
  } = props;

  const initialDate = props.initialDate || {
    day: new Date().getDate(),
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  };

  const [period, changePeriod] = useState(initialDate);

  const logicalMonth = period.month - 1;

  const firstDay = new Date(period.year, logicalMonth, 1);
  const firstWeekday = firstDay.getDay() === 0 ? 7 : firstDay.getDay();

  const monthLength = new Date(period.year, period.month, 0).getDate();
  const previousMonthLength = new Date(period.year, logicalMonth, 0).getDate();

  const isStartOfLimitedPeriod =
    limitedPeriod.start &&
    period.year <= limitedPeriod.start.getFullYear() &&
    period.month <= limitedPeriod.start.getMonth() + 1;

  const isEndOfLimitedPeriod =
    limitedPeriod.end &&
    period.year >= limitedPeriod.end.getFullYear() &&
    period.month >= limitedPeriod.end.getMonth() + 1;

  return (
    <div className="datePicker">
      <div className="datePicker-header">
        <h5 className="datePicker-header-title">{title}</h5>

        <button
          className="datePicker-close-button"
          onClick={(e) => (e.preventDefault(), showDatePicker(false))}
        >
          &times;
        </button>
      </div>

      <div className="datePicker-month-selector">
        <ChevronLeft
          onClick={() => changeMonth(period.month - 1)}
          className={getString([
            {
              value: 'datePicker-month-selector-button-disabled',
              condition: isStartOfLimitedPeriod
            },
            {
              value: 'datePicker-month-selector-button',
              condition: true
            }
          ])}
        />
        {
          [
            'Januari',
            'Februari',
            'Maart',
            'April',
            'Mei',
            'Juni',
            'Juli',
            'Augustus',
            'September',
            'Oktober',
            'November',
            'December'
          ][logicalMonth]
        }{' '}
        {period.year}
        <ChevronRight
          onClick={() => changeMonth(period.month + 1)}
          className={getString([
            {
              value: 'datePicker-month-selector-button-disabled',
              condition: isEndOfLimitedPeriod
            },
            {
              value: 'datePicker-month-selector-button',
              condition: true
            }
          ])}
        />
      </div>

      <table className="datePicker-calendar">
        <thead>
          <tr className="datePicker-calendar-weekdays">
            {['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'].map((label, idx) => (
              <th key={idx} className="datePicker-calendar-day">
                {label}
              </th>
            ))}
          </tr>
        </thead>

        <tbody
          onClick={(e) => setStyles(e)}
          dangerouslySetInnerHTML={{ __html: calendarGrid() }}
        ></tbody>
      </table>

      <div className="datePicker-button-wrap">
        <button className="datePicker-button" onClick={handleClick}>
          Kies datum
        </button>
      </div>
    </div>
  );

  function calendarGrid() {
    let day = 1;
    let prev = 1;
    let next = 1;

    let markup = '<tr>';

    for (let i = 0; i < 9; i++) {
      for (let j = 1; j <= 7; j++) {
        const disabled =
          (isStartOfLimitedPeriod && day < limitedPeriod.start.getDate()) ||
          (isEndOfLimitedPeriod && day > limitedPeriod.end.getDate());

        if (day <= monthLength && (i > 0 || j >= firstWeekday))
          markup += `
            <td
              data-type="current"
              class="datePicker-calendar-day ${
                initialDate.day === day &&
                initialDate.month === period.month &&
                initialDate.year === period.year
                  ? 'datePicker-calendar-day-selected'
                  : ''
              }"
              data-disabled=${disabled}>
              <div class="datePicker-calendar-day-label">${day++}</div>
            </td>`;
        else if (day <= monthLength)
          markup += `
            <td
              data-type="previous"
              data-disabled=${disabled}
              class="datePicker-calendar-day datePicker-calendar-day-fade">
              <div class="datePicker-calendar-day-label">
                ${previousMonthLength - firstWeekday + prev++ + 1}
              </div>
            </td>`;
        else
          markup += `
          <td
            data-type="next"
            data-disabled=${disabled}
            class="datePicker-calendar-day datePicker-calendar-day-fade">
            <div class="datePicker-calendar-day-label">${next++}</div>
          </td>`;
      }

      if (day > monthLength) {
        markup += '</tr>';

        break;
      } else {
        markup += '</tr><tr>';
      }
    }

    return markup;
  }

  function changeMonth(month) {
    if (month > 12) return changePeriod({ month: 1, year: period.year + 1 });
    if (month < 1) return changePeriod({ month: 12, year: period.year - 1 });

    changePeriod({ month, year: period.year });
  }

  function setStyles(e) {
    const td = e.target.closest('td');

    if (td.dataset.disabled === 'true') return;

    const selected = document.querySelector(
      '.datePicker-calendar-day-selected'
    );

    if (selected)
      document
        .querySelector('.datePicker-calendar-day-selected')
        .classList.remove('datePicker-calendar-day-selected');

    td.classList.add('datePicker-calendar-day-selected');
  }

  function handleClick(e) {
    e.preventDefault();

    showDatePicker(false);

    const cell = document.querySelector('.datePicker-calendar-day-selected');

    const { type } = cell.dataset;

    if (cell.dataset.disabled === 'true') return;

    const day = +cell.textContent;

    switch (type) {
      case 'current':
        return setDate(day, period.month, period.year);
      case 'next':
        const nextMonth = period.month + 1;

        if (nextMonth > 12) return setDate(day, 1, period.year + 1);
        else return setDate(day, nextMonth, period.year);
      case 'previous':
        const previousMonth = period.month - 1;

        if (previousMonth < 1) return setDate(day, 12, period.year - 1);
        else return setDate(day, previousMonth, period.year);
    }
  }

  function setDate(day, month, year) {
    changeInitialDate({ month, year, day });

    if (onDateChange) onDateChange(new Date(year, month - 1, day));
  }
}
