import InputRange from 'react-input-range';
import React, { useState, useEffect, useRef } from 'react';

import './Onboarding.scss';
import 'react-input-range/lib/css/index.css';
import { getString } from '../../../helpers/styles';
import { scrollTop } from '../../../helpers/scroll';
import icons from '../../../shared/assets/icons/redesign';
import { isOther, isPartner } from '../../../helpers/user';
import DatePickerComponent from '../DatePicker/DatePicker.jsx';
import { trackAction, usePlatform } from '../../../helpers/tracking';
import {
  mutationFormat,
  getPregnancyPeriod,
  getPastPregnancyPeriod
} from '../../../helpers/date';

const {
  User,
  Check,
  Info,
  LoadMore,
  InputSearch,
  ChevronLeft,
  CalendarSimple
} = icons;

const TYPES = {
  PREG: 'Ik ben zwanger',
  PARTNER: 'Mijn partner is zwanger',
  PREP: 'We willen zwanger worden',
  PROVIDER: 'Ik ben zorgverlener',
  OTHER: 'Anders'
};

export default function Onboarding({
  user,
  config,
  addUserEvent,
  organizations,
  changeUserData,
  organizationsLoading,
  invitingOrganization,
  searchOrganizations,
  changeUserDataWithRedirect
}) {
  const stages = [
    {
      Content: Start
    },
    {
      Content: PhaseSelection,
      hash: 'account-type'
    },
    {
      Content: EstimatedBirthDate,
      hash: 'edd'
    },
    {
      Content: Partner,
      hash: 'partner-email'
    },
    {
      Content: Organization,
      hash: 'provider'
    },
    {
      Content: Finish
    }
  ];

  const platform = usePlatform();
  const [stage, setStage] = useState(0);
  const [allowedStages, setAllowedStages] = useState();

  const [userData, setUserData] = useState({
    edd: user.edd,
    postcode: user.postcode,
    accountType: user.accountType,
    emailPartner: user.emailPartner,
    provider: user.provider?.accountName
  });

  const { Content } = stages[stage];

  useEffect(() => {
    showStageByHash(stages, setStage);

    document.getElementById('header').style.position = 'relative';
    document.querySelector('.bottomMenu').style.display = 'none';
  }, []);

  useEffect(() => {
    if (stages[stage].hash)
      history.replaceState({}, '', `onboarding#${stages[stage].hash}`);
    else history.replaceState({}, '', 'onboarding');

    scrollTop();
  }, [stage]);

  return (
    <div className="onboarding">
      <Content
        stage={stage}
        config={config}
        userData={userData}
        setStage={setStage}
        platform={platform}
        setUserData={setUserData}
        addUserEvent={addUserEvent}
        organizations={organizations}
        allowedStages={allowedStages}
        changeUserData={changeUserData}
        setAllowedStages={setAllowedStages}
        searchOrganizations={searchOrganizations}
        invitingOrganization={invitingOrganization}
        organizationsLoading={organizationsLoading}
        changeUserDataWithRedirect={changeUserDataWithRedirect}
      />
    </div>
  );
}

function Start({ stage, setStage, platform }) {
  return (
    <>
      <div className="onboarding-content-center">
        <div className="onboarding-icon-wrap">
          <User className="onboarding-icon onboarding-user-icon" />
        </div>

        <h3 className="onboarding-title text-center">
          Maak je account compleet
        </h3>

        <p className="onboarding-text text-center">
          Om je te kunnen helpen hebben we nog een aantal gegevens van je nodig
          over je situatie.
        </p>
      </div>

      <button
        className="onboarding-button onboarding-button-primary"
        onClick={() => {
          trackAction(platform, 'onboarding', 'startonboarding');

          nextStage(stage, setStage);
        }}
      >
        Start
      </button>
    </>
  );
}

function PhaseSelection({
  stage,
  config,
  setStage,
  userData,
  platform,
  setUserData,
  allowedStages,
  changeUserData,
  setAllowedStages,
  changeUserDataWithRedirect
}) {
  return (
    <>
      <div className="onboarding-stage-content">
        <StageHeader
          config={config}
          stage={1}
          setStage={setStage}
          platform={platform}
          allowedStages={allowedStages}
        />

        <h3 className="onboarding-title">Waarom heb je je aangemeld?</h3>

        <ul className="onboarding-phase-list">
          {Object.keys(TYPES).map((key) => (
            <li
              key={key}
              className={getString([
                {
                  value: 'onboarding-phase-list-item',
                  condition: true
                },
                {
                  value: 'onboarding-phase-list-item-active',
                  condition: userData.accountType === key
                }
              ])}
              onClick={() => {
                setUserData({ ...userData, accountType: key });

                changeUserData({
                  variables: {
                    accountType: key,
                    ...(['PREG', 'PARTNER'].includes(key) && {
                      status: 'PREGNANT'
                    })
                  }
                });
              }}
            >
              <label className="onboarding-phase-list-item-label">
                <input
                  type="radio"
                  className="onboarding-radio-input"
                  checked={userData.accountType === key}
                  onChange={() => {}}
                />

                <span className="onboarding-checkmark">
                  <Check className="onboarding-checkmark-icon" />
                </span>

                {TYPES[key]}
              </label>
            </li>
          ))}
        </ul>
      </div>

      <button
        className={getString([
          {
            value: 'onboarding-button onboarding-button-primary',
            condition: true
          },
          {
            value: 'onboarding-button-disabled',
            condition: !userData.accountType
          }
        ])}
        onClick={() => {
          if (!userData.accountType) return;

          if (['PREP', 'PROVIDER'].includes(userData.accountType))
            return setStage(4), setAllowedStages([1, 4]);

          if (isPartner(userData)) {
            changeUserData({ variables: { eddStatus: 'SKIP' } });

            nextStage(stage, setStage);

            return setAllowedStages([1, 2, 4]);
          }

          if (isOther(userData))
            return changeUserDataWithRedirect({
              variables: { weekMail: 'Y', eddStatus: 'SKIP' }
            });

          setAllowedStages(null);

          nextStage(stage, setStage);

          trackAction(platform, 'onboarding', 'setstatus');
        }}
      >
        Volgende
      </button>
    </>
  );
}

function EstimatedBirthDate({
  stage,
  config,
  setStage,
  userData,
  platform,
  setUserData,
  allowedStages,
  changeUserData
}) {
  const estimatedBirthDate = new Date();

  estimatedBirthDate.setDate(estimatedBirthDate.getDate() + 39 * 7);

  const [selectedDate, setSelectedDate] = useState(estimatedBirthDate);
  const [birthDateCalculator, showBirthDateCalculator] = useState(false);
  const [birthDateCalculationResult, showBirthDateCalculationResult] =
    useState();

  useEffect(() => {
    scrollTop();
  }, [birthDateCalculator, birthDateCalculationResult]);

  if (birthDateCalculationResult)
    return (
      <>
        <div className="onboarding-stage-content">
          <StageHeader
            config={config}
            setStage={setStage}
            platform={platform}
            onPreviousStage={() => showBirthDateCalculationResult(false)}
          />

          <div className="onboarding-content-center">
            <div className="onboarding-icon-wrap">
              <CalendarSimple
                className="onboarding-icon onboarding-calendar-icon"
              />
            </div>

            <h3 className="onboarding-title text-center">
              Je uitgerekende datum is:
              <br />
              <span className="onboarding-title-attention">
                {format(birthDateCalculationResult.date)}
              </span>
            </h3>

            <p className="onboarding-text text-center">
              Je bent nu in{' '}
              <span className="onboarding-text-bold">
                week {birthDateCalculationResult.week}
              </span>{' '}
              van je zwangerschap
            </p>
          </div>
        </div>

        <div className="onboarding-buttons-wrap">
          <button
            className="onboarding-button onboarding-button-primary"
            onClick={() => {
              nextStage(isPartner(userData) ? stage + 1 : stage, setStage);

              trackAction(platform, 'onboarding', 'setedd');

              changeUserData({
                variables: {
                  edd: mutationFormat(birthDateCalculationResult.date),
                  eddStatus: 'OK'
                }
              });

              setUserData({
                ...userData,
                edd: birthDateCalculationResult.date
              });
            }}
          >
            Volgende
          </button>

          <button
            className="onboarding-button onboarding-button-secondary"
            onClick={() => showBirthDateCalculationResult(false)}
          >
            Bereken opnieuw
          </button>
        </div>
      </>
    );

  if (birthDateCalculator)
    return (
      <BirthDateCalculator
        showBirthDateCalculationResult={showBirthDateCalculationResult}
        StageHeader={() => (
          <StageHeader
            config={config}
            platform={platform}
            setStage={setStage}
            stage={2}
            allowedStages={allowedStages}
            onPreviousStage={() =>
              birthDateCalculator
                ? showBirthDateCalculator(false)
                : previousStage(stage, allowedStages, setStage)
            }
          />
        )}
      />
    );

  return (
    <>
      <div className="onboarding-stage-content">
        <StageHeader
          stage={2}
          config={config}
          platform={platform}
          setStage={setStage}
          allowedStages={allowedStages}
          onPreviousStage={() =>
            birthDateCalculator
              ? showBirthDateCalculator(false)
              : previousStage(stage, allowedStages, setStage)
          }
        />

        <h3 className="onboarding-title">Wat is je uitgerekende datum?</h3>

        <h5 className="onboarding-subtitle">
          Dit kun je altijd later nog aanpassen in je accountinstellingen
        </h5>

        <div className="onboarding-input-wrap">
          <DateInput
            selected={userData.edd ? new Date(userData.edd) : selectedDate}
            period={getPregnancyPeriod()}
            title="Aanpassen van uitgerekende datum"
            onDateChange={(date) => setSelectedDate(date)}
          />
        </div>

        <p
          className="onboarding-link"
          onClick={() => showBirthDateCalculator(true)}
        >
          Ik weet het niet – Datum berekenen
        </p>
      </div>

      <button
        className="onboarding-button onboarding-button-primary"
        onClick={() => {
          nextStage(isPartner(userData) ? stage + 1 : stage, setStage);

          trackAction(platform, 'onboarding', 'setedd');

          changeUserData({
            variables: {
              edd: mutationFormat(selectedDate),
              eddStatus: 'OK'
            }
          });

          setUserData({ ...userData, edd: selectedDate });
        }}
      >
        Bevestigen
      </button>
    </>
  );
}

function Partner({
  stage,
  config,
  setStage,
  userData,
  platform,
  setUserData,
  allowedStages,
  changeUserData
}) {
  const [email, setEmail] = useState(userData.emailPartner);

  return (
    <>
      <div className="onboarding-stage-content">
        <StageHeader
          stage={3}
          config={config}
          setStage={setStage}
          platform={platform}
          allowedStages={allowedStages}
        />

        <h3 className="onboarding-title">
          Breng je partner op de hoogte van de ontwikkelingen in je buik
        </h3>

        <label className="onboarding-label">
          <span className="onboarding-label-text">E-mailadres</span>

          <input
            type="email"
            value={email}
            autoComplete="off"
            className="onboarding-input"
            onClick={(e) => e.preventDefault()}
            placeholder="E-mailadres van je partner"
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
      </div>

      <div className="onboarding-buttons-wrap">
        <button
          className={getString([
            {
              value: 'onboarding-button onboarding-button-primary',
              condition: true
            },
            {
              value: 'onboarding-button-disabled',
              condition: !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                email
              )
            }
          ])}
          onClick={() => {
            nextStage(stage, setStage);

            trackAction(platform, 'onboarding', 'setpartneremail');

            changeUserData({
              variables: { emailPartner: email, partnerInviteStatus: 'OK' }
            });

            setUserData({ ...userData, emailPartner: email });
          }}
        >
          Verstuur uitnodiging
        </button>

        <button
          className="onboarding-button onboarding-button-secondary"
          onClick={() => {
            nextStage(stage, setStage);

            trackAction(platform, 'onboarding', 'skippartneremail');

            changeUserData({ variables: { partnerInviteStatus: 'SKIP' } });
          }}
        >
          Sla over
        </button>
      </div>
    </>
  );
}

function Organization({
  stage,
  config,
  userData,
  setStage,
  platform,
  organizations,
  allowedStages,
  changeUserData,
  searchOrganizations,
  organizationsLoading
}) {
  const postCodeInput = useRef();

  const [provider, setProvider] = useState(null);
  const [postCode, setPostCode] = useState(userData.postcode);
  const [displayedOrganizationCount, setDisplayedOrganizationCount] =
    useState(4);

  return (
    <>
      <div className="onboarding-stage-content">
        <StageHeader
          stage={4}
          config={config}
          setStage={setStage}
          platform={platform}
          allowedStages={allowedStages}
        />

        <h3 className="onboarding-title">Kies je zorgverlener</h3>

        <h5 className="onboarding-subtitle">
          Door je account te koppelen aan je zorgverlener houden we je op de
          hoogte van je voortgang en kan je makkelijk contact met hen opnemen
        </h5>

        <form
          className="onboarding-organization-search-form"
          onSubmit={(e) => {
            e.preventDefault();
            searchOrganizations({
              variables: {
                postalcode: e.target.code.value,
                distance: 1000
              }
            });
          }}
        >
          <input
            name="code"
            type="number"
            pattern="[0-9]+"
            value={postCode}
            ref={postCodeInput}
            onKeyPress={(e) => !/[0-9]/.test(event.key) && e.preventDefault()}
            onChange={(e) =>
              e.target.value.length <= 4 && setPostCode(e.target.value)
            }
            placeholder="Zoek op postcode"
            className="onboarding-input onboarding-search-input"
          />

          <InputSearch
            onClick={() => {
              searchOrganizations({
                variables: {
                  postalcode: postCodeInput.current.value,
                  distance: 1000
                }
              });
            }}
            className="onboarding-organization-search-icon"
          />

          <button
            className="onboarding-button onboarding-button-primary
              onboarding-organization-search-button"
            onClick={() => {
              trackAction(platform, 'onboarding', 'searchprovider');

              searchOrganizations({
                variables: {
                  postalcode: postCodeInput.current.value,
                  distance: 1000
                }
              });
            }}
          >
            Zoeken
          </button>
        </form>

        {organizationsLoading && (
          <p className="onboarding-info-message">Laden...</p>
        )}

        {organizations && !organizations?.length && (
          <p className="onboarding-info-message">
            <Info className="onboarding-info-message-icon" />
            We hebben geen zorgverleners kunnen vinden binnen deze postcode
          </p>
        )}

        {!!organizations?.length && (
          <>
            <p className="onboarding-info-message">
              <span className="onboarding-text-bold">
                {organizations?.length} zorgverleners&nbsp;
              </span>
              bij jou in de buurt:
            </p>

            <ul className="onboarding-organization-list">
              {organizations
                .slice(0, displayedOrganizationCount)
                .map((item) => (
                  <li
                    key={item.id}
                    className={getString([
                      {
                        value: 'onboarding-organization-list-item',
                        condition: true
                      },
                      {
                        value: 'onboarding-organization-list-item-active',
                        condition: provider?.accountName === item.accountName
                      }
                    ])}
                  >
                    <label>
                      <input
                        type="radio"
                        onChange={() => {}}
                        className="onboarding-radio-input"
                        onClick={() => setProvider(item)}
                        checked={provider?.accountName === item.accountName}
                      />

                      <span
                        className="onboarding-checkmark
                        onboarding-organization-checkmark"
                      >
                        <Check className="onboarding-checkmark-icon" />
                      </span>

                      <OrganizationCard
                        logoUrl={item.logoUrl}
                        name={item.displayName}
                        distance={item.distance}
                      />
                    </label>
                  </li>
                ))}
            </ul>

            {displayedOrganizationCount < organizations.length && (
              <button
                onClick={() => {
                  trackAction(platform, 'onboarding', 'loadmoreproviders');

                  setDisplayedOrganizationCount(displayedOrganizationCount + 4);
                }}
                className="onboarding-loadmore-button"
              >
                <LoadMore className="onboarding-loadmore-icon" />
                Laad meer
              </button>
            )}
          </>
        )}
      </div>

      <div className="onboarding-buttons-wrap">
        <button
          className={getString([
            {
              value: 'onboarding-button onboarding-button-primary',
              condition: true
            },
            {
              value: 'onboarding-button-disabled',
              condition: !provider
            }
          ])}
          onClick={() => {
            if (provider) {
              nextStage(stage, setStage);

              trackAction(platform, 'onboarding', 'setprovider');

              changeUserData({
                variables: {
                  weekMail: 'Y',
                  provider: provider.accountName,
                  providerStatus: 'OK'
                }
              });
            }
          }}
        >
          Kies zorgverlener
        </button>

        <button
          className="onboarding-button onboarding-button-secondary"
          onClick={() => {
            nextStage(stage, setStage);

            trackAction(platform, 'onboarding', 'skipprovider');

            changeUserData({
              variables: {
                weekMail: 'Y',
                providerStatus: 'SKIP'
              }
            });
          }}
        >
          Ik heb geen zorgverlener
        </button>
      </div>
    </>
  );
}

function Finish({ addUserEvent }) {
  useEffect(() => {
    addUserEvent({ variables: { type: 'onboarding-completed', data: '{}' } });
  }, []);

  return (
    <>
      <div className="onboarding-content-center">
        <div className="onboarding-icon-wrap">
          <Check className="onboarding-icon onboarding-check-icon" />
        </div>

        <h3 className="onboarding-title text-center">
          Je gegevens zijn opgeslagen
        </h3>

        <p className="onboarding-text text-center">
          Al je persoonlijke gegevens zijn veilig opgeslagen in je account. Je
          kan je gegevens op ieder moment wijzigen
        </p>
      </div>

      <a
        className="onboarding-button onboarding-button-primary"
        href="/dashboard"
      >
        Naar mijn Dashboard
      </a>
    </>
  );
}

export function DateInput({
  title,
  period,
  selected,
  placeholder,
  onDateChange
}) {
  const [datePicker, showDatePicker] = useState(false);

  const [initialDate, changeInitialDate] = useState(
    selected
      ? {
        day: selected.getDate(),
        year: selected.getFullYear(),
        month: selected.getMonth() + 1
      }
      : null
  );

  return (
    <>
      <div
        className="onboarding-date-input-wrap"
        onClick={() => showDatePicker(true)}
      >
        <div className="onboarding-input onboarding-date-input">
          {initialDate ? (
            <span className="onboarding-date-input-value">
              {format(
                new Date(
                  initialDate.year,
                  initialDate.month - 1,
                  initialDate.day
                )
              )}
            </span>
          ) : (
            <span className="onboarding-date-input-placeholder">
              {placeholder}
            </span>
          )}
        </div>

        <CalendarSimple className="onboarding-date-input-icon" />
      </div>

      {datePicker && (
        <div className="onboarding-date-picker">
          <DatePickerComponent
            title={title}
            period={period}
            initialDate={initialDate}
            onDateChange={onDateChange}
            showDatePicker={showDatePicker}
            changeInitialDate={changeInitialDate}
          />
        </div>
      )}
    </>
  );
}

export function BirthDateCalculator({
  onCalculate,
  StageHeader,
  showBirthDateCalculationResult
}) {
  const [menstruationDate, setMenstruationDate] = useState(false);
  const [menstruationCycleLength, setMenstruationCycleLength] = useState(28);

  return (
    <>
      <div className="onboarding-stage-content">
        {StageHeader && <StageHeader />}

        <h3 className="onboarding-title">Datum uitrekenen</h3>

        <div className="onboarding-label">
          <span className="onboarding-label-text">
            Eerste dag van je laatste menstruatie
          </span>

          <DateInput
            placeholder="Kies datum"
            selected={menstruationDate}
            period={getPastPregnancyPeriod()}
            title="Eerste dag van je laatste menstruatie"
            onDateChange={(date) => setMenstruationDate(date)}
          />
        </div>

        <label className="onboarding-label">
          <span className="onboarding-label-text">
            Lengte van je cyclus in dagen
          </span>

          <InputRange
            maxValue={35}
            minValue={25}
            value={menstruationCycleLength}
            onChange={(value) => setMenstruationCycleLength(value)}
          />
        </label>
      </div>

      <button
        className={getString([
          {
            value: 'onboarding-button onboarding-button-primary',
            condition: true
          },
          {
            value: 'onboarding-button-disabled',
            condition: !menstruationDate
          }
        ])}
        onClick={() => {
          if (menstruationDate) {
            calculateBirthDate(
              menstruationCycleLength,
              menstruationDate,
              showBirthDateCalculationResult
            );

            if (onCalculate) onCalculate();
          }
        }}
      >
        Datum uitrekenen
      </button>
    </>
  );
}

function OrganizationCard({ logoUrl, name, distance }) {
  return (
    <div className="onboarding-organization-card">
      <img
        className="onboarding-organization-image"
        width="45"
        height="45"
        src={logoUrl}
        alt="organization-logo"
      />

      <p className="onboarding-organization-card-text">
        {name}

        {distance && (
          <span className="onboarding-organization-card-text-small">
            {distance} km
          </span>
        )}
      </p>
    </div>
  );
}

function StageHeader({
  stage,
  config,
  setStage,
  platform,
  allowedStages,
  onPreviousStage
}) {
  return (
    <div className="onboarding-stage-header">
      <div>
        <ChevronLeft
          className="onboarding-previous-step-button"
          onClick={() =>
            onPreviousStage
              ? onPreviousStage()
              : previousStage(stage, allowedStages, setStage)
          }
        />

        {stage && <span className="onboarding-step-label">{stage} / 4</span>}
      </div>

      <button
        className="onboarding-logout-button"
        onClick={() => {
          trackAction(platform, 'onboarding', 'exitonboarding');

          document.cookie =
            'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          location.href = config.LOGOUT_URL;
        }}
      >
        &times;
      </button>
    </div>
  );
}

function nextStage(stage, setStage) {
  setStage(stage + 1);
}

function previousStage(stage, allowedStages, setStage) {
  if (allowedStages) {
    if (allowedStages.includes(stage - 1)) return setStage(stage - 1);

    stage -= 2;

    while (stage >= 0) {
      if (allowedStages.includes(stage)) return setStage(stage);

      stage--;
    }

    return setStage(0);
  }

  setStage(stage - 1);
}

function format(date) {
  return date.toLocaleString('nl-NL', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
}

function showStageByHash(stages, setStage) {
  const hash = window.location.hash.slice(1);

  if (!hash) return;

  const stage = stages.findIndex((stage) => stage.hash === hash);

  if (~stage) setStage(stage);
}

function calculateBirthDate(length, date, showBirthDateCalculationResult) {
  const deadLineDate = new Date();
  const birthDate = new Date(date);

  deadLineDate.setDate(deadLineDate.getDate() - 7);

  const differenceInWeeks = Math.ceil(
    (deadLineDate.getTime() - birthDate.getTime()) / (1000 * 3600 * 24 * 7)
  );

  // Naegele's Rule
  const delta = 280 - 28 + +length;

  birthDate.setDate(birthDate.getDate() + delta);

  showBirthDateCalculationResult({
    week: Math.abs(differenceInWeeks) || 1,
    date: birthDate
  });
}
